import {
  ContractReportQueryApiFactory,
  ContractReportApiFactory,
  InsuranceCategory,
} from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getContractReports,
  getContractReportsCommissionMatching,
  getContractReportLifeInsuranceCsv,
  getContractReportNonLifeInsuranceCsv,
} = ContractReportQueryApiFactory(...args, undefined, axiosInstance);
const {
  postContractReport,
  getContractReport,
  putContractReport,
  deleteContractReport,
} = ContractReportApiFactory(...args, undefined, axiosInstance);

/**
 * This function calls GetContractReportLifeInsuranceCsvAPI API with filtering conditions as params.
 * args: [contractorAttribute: ContractorAttribute, keyword?: string | undefined, staffId?: number | undefined, departmentId?: number | undefined,
 *  insuranceCompanyId?: number | undefined, marketId?: number | undefined, contractReportOnFrom?: string | undefined,
 *  contractReportOnTo?: string | undefined, options?: any]
 */
const exportLifeInsuranceCsvBy = safeAsyncCall(
  async (...args: Parameters<typeof getContractReportLifeInsuranceCsv>) => {
    const res = await getContractReportLifeInsuranceCsv(...args);
    return res;
  }
);

/**
 * This function calls GetContractReportNonLifeInsuranceCsv API with filtering conditions as params.
 * args: [contractorAttribute: ContractorAttribute, keyword?: string | undefined, staffId?: number | undefined, departmentId?: number | undefined,
 *  policyNumber?: string | undefined, insuranceCompanyId?: number | undefined, marketId?: number | undefined, contractReportOnFrom?: string | undefined,
 *  contractReportOnTo?: string | undefined, options?: any]
 * @returns
 */
const exportNonLifeInsuranceCsvBy = safeAsyncCall(
  async (...args: Parameters<typeof getContractReportNonLifeInsuranceCsv>) => {
    const res = await getContractReportNonLifeInsuranceCsv(...args);
    return res;
  }
);

export default function useContractReportQueryService() {
  return {
    /**
     * Get contract reports by the given parameters.
     * @param args
     */
    get: safeAsyncCall(
      async (...args: Parameters<typeof getContractReport>) => {
        const res = await getContractReport(...args);
        return res;
      }
    ),

    /**
     * Add contract reports by the given parameters.
     * @param args
     */

    add: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postContractReport>) => {
        const res = await postContractReport(...args);
        const { data: id } = res;
        return id;
      }
    ),

    /**
     * Update contract reports by the given parameters.
     * @param args
     */
    update: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof putContractReport>) => {
        const res = await putContractReport(...args);
        return res;
      }
    ),

    /**
     * Delete contract reports by the given parameters.
     * `delete` is a reserved keyword in JavaScript, so we use `deleteContractReport` instead.
     * @param args
     */
    deleteContractReport: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof deleteContractReport>) => {
        const res = await deleteContractReport(...args);
        return res;
      }
    ),

    /**
     * Find contract reports by the given parameters.
     * @param args
     * @returns
     */

    // Wrap the function with safeAsyncCall to catch errors
    findBy: safeAsyncCall(
      async (...args: Parameters<typeof getContractReports>) => {
        const res = await getContractReports(...args);
        const { data } = res;

        return data;
      }
    ),

    /**
     * Find contract report commission matches by the given parameters.
     * @param args
     * @returns
     */

    // Wrap the function with safeAsyncCall to catch errors
    findMatch: safeAsyncCall(
      async (
        ...args: Parameters<typeof getContractReportsCommissionMatching>
      ) => {
        const res = await getContractReportsCommissionMatching(...args);

        return res.data;
      }
    ),

    // Export CSV file based on the given parameters which are different for life and non-life insurance.
    exportBy: safeAsyncCall(
      async (
        type: InsuranceCategory,
        ...args:
          | Parameters<typeof exportLifeInsuranceCsvBy>
          | Parameters<typeof exportNonLifeInsuranceCsvBy>
      ) => {
        const [
          keyword,
          contractReportId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          options,
        ] = args as Parameters<typeof exportNonLifeInsuranceCsvBy>;
        const res =
          type === "lifeInsurance"
            ? await exportLifeInsuranceCsvBy(
                keyword,
                contractReportId,
                staffId,
                departmentId,
                insuranceCompanyId,
                marketId,
                contractReportOnFrom,
                contractReportOnTo,
                options
              )
            : await exportNonLifeInsuranceCsvBy(
                keyword,
                contractReportId,
                staffId,
                departmentId,
                policyNumber, // policyNumber is used for non-life insurance
                insuranceCompanyId,
                marketId,
                contractReportOnFrom,
                contractReportOnTo,
                options
              );
        return res;
      }
    ),
  };
}
