<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import {
  generateOptionsFromEnum,
  getOptionsForSelectbox,
} from "@/utils/helper";
import { useRouter } from "vue-router";
import ComponentWrapper from "@/components/ComponentWrapper.vue";
import { usePotentialCustomerIndividualStore } from "@/stores/potential-customer-individual";
import { usePotentialCustomerCorporationStore } from "@/stores/potential-customer-corporation";
import { useContractReportStore } from "@/stores/ContractReports/ContractReports";
import SearchAutocompleteWrapper from "@/components/SearchAutoComplete/SearchAutocompleteWrapper.vue";
import { useSecuritiesStore } from "@/stores/SecuritiesManagement/SecuritiesManagement";
import { useMarketQueryService } from "@/services/use-market-query-service";
import { ContractReportSort, type PotentialCustomerType } from "@/api";
import { useLoggedInUserStore } from "@/stores/LoggedInUser";

const router = useRouter();

const props = defineProps({
  drawer: Boolean,
  contractorAttribute: String,
});

const keyword = ref("");

// Initialize v-model values
const potentialCustomerType = ref("");
const marketId = ref("");
const staffId = ref<number>();
const createdOnFrom = ref();
const createdOnTo = ref();
const isMyself = ref();
const potentialCustomerId = ref("");

const loggedInUser = ref<number>();

// v-model can't be initialized with 'undefined', but 'null' will negate placeholder text,
// so search requests can use these computed values instead.
const computedMarketId = computed(() => {
  return marketId.value === "" ? undefined : Number(marketId.value);
});
const computedPotentialCustomerType = computed(() => {
  return potentialCustomerType.value === ""
    ? undefined
    : (potentialCustomerType.value as PotentialCustomerType);
});
const isFalsy = (value: any) => {
  return value === null || value === "" || value === undefined || isNaN(value);
};
const computedStaffId = computed(() => {
  return isFalsy(staffId.value) // They are called as falsy
    ? undefined
    : Number(staffId.value);
});
const computedPotentialCustomerId = computed(() => {
  return potentialCustomerId.value === ""
    ? undefined
    : Number(potentialCustomerId.value);
});

const noUserIdError = ref(false);
function onClickMyself() {
  // Toggle isMyself value first.
  isMyself.value = !isMyself.value;

  const loggedInUser = useLoggedInUserStore().loggedInUserId;
  // If isMyself is true, staffId will be set to the current user's ID.
  if (isMyself.value) {
    if (loggedInUser && !isNaN(loggedInUser)) {
      staffId.value = loggedInUser;
    } else {
      noUserIdError.value = true;
    }
  } else {
    // If isMyself is false, staffId will be set to undefined.
    noUserIdError.value = false;
    staffId.value = undefined;
  }
}

// reset checkbox when staffId is undefined
watch(
  () => staffId.value,
  (newId) => {
    if (!newId) {
      isMyself.value = false;
    }
  }
);
const typeOptions = generateOptionsFromEnum("potentialCustomerType", [
  "myself",
  "market",
  "jointRecruitment",
]);
const attribute = ref("individual");

const searchedAtLeastOne = ref(false);

const onCreate = () => {
  const potentialCustomerIndivisualRoute = router.resolve({
    name: "CreatePotentialCustomerIndividual",
  });

  const potentialCustomerCorporationRoute = router.resolve({
    name: "CreatePotentialCustomerCorporation",
  });

  if (attribute.value === "individual") {
    window.open(potentialCustomerIndivisualRoute.href, "_blank");
  } else window.open(potentialCustomerCorporationRoute.href, "_blank");
};

/**
 * Search for customers and contracts with the specified conditions.
 */
const potentialCustomerIndividualStore = usePotentialCustomerIndividualStore();
const potentialCustomerCorporationStore =
  usePotentialCustomerCorporationStore();
const contractReportStore = useContractReportStore();
const securitiesStore = useSecuritiesStore();

const search = async (params: object = {}) => {
  // clearing previous search results
  potentialCustomerIndividualStore.clearCustomers();
  potentialCustomerCorporationStore.clearCorporations();
  contractReportStore.clearContracts();
  securitiesStore.clearSecurities();

  const keywordParam = ref<string | undefined>(undefined);

  if (keyword.value != "") keywordParam.value = keyword.value;

  if (attribute.value == "individual") {
    await potentialCustomerIndividualStore.customersFindBy(
      1,
      1000,
      keywordParam.value,
      computedPotentialCustomerId.value,
      computedStaffId.value,
      computedMarketId.value,
      computedPotentialCustomerType.value,
      createdOnFrom?.value,
      createdOnTo?.value
    );
    await contractReportStore.contractsFindBy(
      false,
      ContractReportSort.ContractReportOn,
      1,
      1000,
      undefined,
      attribute.value,
      keywordParam.value,
      computedPotentialCustomerId.value,
      undefined,
      computedStaffId.value,
      undefined,
      undefined,
      undefined,
      computedMarketId.value
    );
    await securitiesStore.securitiesFindBy(
      false,
      attribute.value,
      1,
      1000,
      undefined,
      keywordParam.value,
      computedPotentialCustomerId.value,
      computedStaffId.value,
      undefined,
      undefined,
      undefined,
      computedMarketId.value
    );
  } else if (attribute.value == "corporation") {
    await potentialCustomerCorporationStore.corporationsFindBy(
      1,
      1000,
      keywordParam.value,
      computedPotentialCustomerId.value,
      computedStaffId.value,
      computedMarketId.value,
      computedPotentialCustomerType.value,
      createdOnFrom.value,
      createdOnTo.value
    );
    await contractReportStore.contractsFindBy(
      false,
      ContractReportSort.ContractReportOn,
      1,
      1000,
      undefined,
      attribute.value,
      keywordParam.value,
      computedPotentialCustomerId.value,
      undefined,
      computedStaffId.value,
      undefined,
      undefined,
      undefined,
      computedMarketId.value
    );
    await securitiesStore.securitiesFindBy(
      false,
      attribute.value,
      1,
      1000,
      undefined,
      keywordParam.value,
      computedPotentialCustomerId.value,
      computedStaffId.value,
      undefined,
      undefined,
      undefined,
      computedMarketId.value
    );
  }
  computedContractorAttribute.value = attribute.value;
  searchedAtLeastOne.value = true;
};

const emit = defineEmits<{
  (e: "update:contractorAttribute", attribute: string | undefined): void;
  (e: "change", toggle: boolean): void;
}>();

const computedContractorAttribute = computed({
  get: () => attribute.value,
  set: (attribute) => {
    console.log("changes attr");
    emit("update:contractorAttribute", attribute);
  },
});

const markets = ref<any[]>([]);
/**
 * Fetch names of markets.
 */
onMounted(async () => {
  const res = await useMarketQueryService().findBy(1, 1000);
  if (!res) return;

  const { results } = res;
  markets.value = getOptionsForSelectbox(results, "marketName", "marketId");
});
</script>

<template>
  <!-- Search filter -->

  <!-- <v-navigation-drawer width="384" v-model="toggleDisplay" temporary> -->
  <component-wrapper class="component-specific-style">
    <v-form @submit.prevent>
      <v-card-title>検索条件</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="6">
              <FormKit
                type="search"
                placeholder="姓名（カナ・漢字）を記入してください"
                label="姓名（カナ・漢字）検索"
                wrapper-class="search-bar"
                validation="length:1,500"
                v-model="keyword"
                suffix-icon="trash"
                @suffix-icon-click="keyword = ''"
              />
            </v-col>
            <v-col cols="2" class="mt-4">
              <FormKit
                type="checkbox"
                label="法人検索する"
                on-value="corporation"
                off-value="individual"
                v-model="attribute"
              />
            </v-col>
            <v-col cols="2">
              <FormKit label="案件ID" v-model="potentialCustomerId" />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <search-autocomplete-wrapper
                label="担当募集人"
                :isEditable="true"
                v-model="staffId"
                :debounce="20"
                :isOptional="true"
                :staffId="staffId"
              />
              <FormKit
                type="checkbox"
                label="自身が購入した案件（私が担当）"
                v-model="isMyself"
                @click="onClickMyself"
                :disabled="isMyself"
              />
            </v-col>
            <v-col md="2">
              <FormKit
                type="select"
                placeholder="案件区分を選択"
                :options="typeOptions"
                label="案件区分"
                v-model="potentialCustomerType"
                suffix-icon="trash"
                @suffix-icon-click="potentialCustomerType = ''"
              />
            </v-col>

            <v-col md="3">
              <FormKit
                type="select"
                placeholder="マーケットを選択"
                :options="markets"
                label="マーケット"
                v-model="marketId"
                potentialCustomerType
                suffix-icon="trash"
                @suffix-icon-click="marketId = ''"
              />
            </v-col>
            <v-col md="2">
              <FormKit
                type="date"
                label="登録日From"
                min="1900-01-01"
                max="2100-12-31"
                v-model="createdOnFrom"
              />
            </v-col>
            <v-col md="2">
              <FormKit
                type="date"
                label="登録日To"
                min="1900-01-01"
                max="2100-12-31"
                v-model="createdOnTo"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="d-flex flex-row">
        <v-sheet class="ma-2 pa-2">
          <v-btn size="large" color="primary" @click="search">検索</v-btn>
        </v-sheet>
        <v-sheet class="ma-2 pa-2">
          <v-btn
            size="large"
            :disabled="!searchedAtLeastOne"
            @click="onCreate"
            color="primary"
          >
            新規作成</v-btn
          >
        </v-sheet>
      </v-card-text>
    </v-form>
  </component-wrapper>

  <!-- </v-navigation-drawer> -->
</template>

<style lang="scss" scoped>
.button:hover {
  background-color: #002ead;
  transition: 0.7s;
}

// If you want to customize FormKit style, use combination with
// a selector created in this SFC and call :deep.
.component-specific-style :deep([data-invalid] .formkit-inner) {
  border-color: red;
  box-shadow: 0 0 0 1px red;
}
</style>
