import {
  PotentialCustomerQueryApiFactory,
  PotentialCustomerApiFactory,
  PotentialCustomerType,
  type GetPotentialCustomerIndividualPersons200ResponseResultsInner,
  type GetPotentialCustomer200Response,
  type PutPotentialCustomerRequest,
  type PostPotentialCustomerRequest,
} from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  PotentialCustomerIndividualScreenItem,
  PotentialCustomerIndividualListScreenItem,
} from "@/types/PotentialCustomer/potential-customer-individual";
import {
  removeUndefinedAndEmptyStringFromObject,
  safeAsyncCallAndRaiseError,
} from "@/utils/helper";
import { defineStore, storeToRefs } from "pinia";
import { ref, computed, type Ref } from "vue";
import axiosInstance from "@/utils/axios-customized-instance";
import { validateArrayLength } from "@/utils/helper";
import type { PotentialCustomerCorporationScreenItem } from "@/types/PotentialCustomer/potential-customer-corporation";
import _ from "lodash-es";

/**
 * Import partial stores
 */
import { usePotentialCustomerInfoStore } from "@/stores/PotentialCustomerManagement/PotentialCustomerInfo";
import { useMarketInfoStore } from "./PotentialCustomerManagement/MarketInfo";
import { useStaffInfoStore } from "./PotentialCustomerManagement/StaffInfo";
import { useFamilyInfoStore } from "./PotentialCustomerManagement/FamilyInfo";
import { usePotentialCustomerIndividualStore as usePCIndividualStore } from "./PotentialCustomerManagement/PotentialCustomerIndividual";

const potentialCustomerId = ref<number | undefined>();
const createdAt = ref<string>("");

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

/**
 * Functions and types generated by OpenAPI Generator
 */
const { getPotentialCustomerIndividualPersons } =
  PotentialCustomerQueryApiFactory(...args, undefined, axiosInstance);
export type ParamsForFindBy = Parameters<
  typeof getPotentialCustomerIndividualPersons
>;

const {
  postPotentialCustomer,
  getPotentialCustomer,
  putPotentialCustomer,
  deletePotentialCustomer: deletePC,
} = PotentialCustomerApiFactory(...args, undefined, axiosInstance);

/**
 * User defined guard function.
 *
 * If the value is not null, it means that the type of
 * the value is Response(GetIndividualPotentialCustomersResponse["results"]).
 */
const isDataExists = (value: any): value is Response => {
  return value !== null;
};

/**
 * return true if the `potentialCustomerType` is neither "market" nor "jointRecruitment"`
 * @param value
 * @returns
 */
const shouldRemoveMarketInfo = (value: string) => {
  return value !== "market" && value !== "jointRecruitment";
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
// ref()s become state properties
// computed()s become getters
// function()s become actions
// https://pinia.vuejs.org/cookbook/composables.html#setup-stores
export const usePotentialCustomerIndividualStore = defineStore(
  "potential-customer-individual",
  () => {
    // state

    // This ref is used to show the message when no data is found
    const noDataFound = ref<boolean>(false);

    const potentialCustomerIndividual = usePCIndividualStore();
    const potentialCustomerInfo = usePotentialCustomerInfoStore();
    const marketInfo = useMarketInfoStore();
    const staffInfo = useStaffInfoStore();
    const familyInfo = useFamilyInfoStore();

    const { currentItem: familyInfoData } = storeToRefs(familyInfo);
    const { currentItem: potentialCustomerInfoData } = storeToRefs(
      potentialCustomerInfo
    );
    const { currentItem: marketInfoData } = storeToRefs(marketInfo);
    const { currentItem: staffInfoData } = storeToRefs(staffInfo);
    const { currentItem: potentialCustomerIndividualData } = storeToRefs(
      potentialCustomerIndividual
    );

    const currentItem: Ref<PotentialCustomerIndividualScreenItem> = ref({
      potentialCustomerId,
      createdAt,
      potentialCustomerIndividual: potentialCustomerIndividualData,
      potentialCustomerMeta: potentialCustomerInfoData,
      staff: staffInfoData,
      family: familyInfoData,
      market: marketInfoData,
    });

    const customers = ref<
      GetPotentialCustomerIndividualPersons200ResponseResultsInner[] | undefined
    >(undefined);

    // getters
    /**
     * The collection for search results
     */
    const customersList = computed(() => {
      if (customers.value === undefined) {
        return undefined;
      }

      const res = customers.value.map((customer) => {
        return convertGetPotentialCustomerIndividualPersons200ResponseResultsInnerToListItem(
          customer
        );
      });

      return res;
    });

    const customersRef = computed(() => {
      return customers;
    });

    // actions
    const clearCustomers = () => {
      customers.value = undefined;
    };

    const clearPotentialCustomer = () => {
      potentialCustomerIndividual.$reset();
      potentialCustomerInfo.$reset();
      marketInfo.$reset();
      staffInfo.$reset();
      familyInfo.$reset();
    };

    const add = async (customer: PotentialCustomerIndividualScreenItem) => {
      const res = await postPotentialCustomer(
        convertScreenItemToPostPotentialCustomerRequest(
          customer
        ) as PostPotentialCustomerRequest
      );
      const { data: id } = res;
      return id;
    };

    const get = async (id: number) => {
      try {
        const res = await getPotentialCustomer(id);
        const { data, status } = res;

        if (status === 200)
          return convertGetPotentialCustomer200ResponseToScreenItem(data);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to get: " + error);
      }
    };

    const update = async (customer: PotentialCustomerIndividualScreenItem) => {
      // Console.error is customer.potentialCustomerId is undefined
      if (!customer.potentialCustomerId) {
        throw new Error("Invalid potentialCustomerId");
      }
      const res = await putPotentialCustomer(
        customer.potentialCustomerId,
        convertScreenItemToPutPotentialCustomerRequest(
          customer
        ) as PutPotentialCustomerRequest
      );
      const { status } = res;
      if (status != 204) throw new Error("Failed to update");
    };

    const customersFindBy = async (...args: ParamsForFindBy) => {
      await getPotentialCustomerIndividualPersons(...args).then((response) => {
        // reset the noDataFound flag
        noDataFound.value = false;
        if (isDataExists(response.data) && response.data.results) {
          customers.value = response.data.results;

          // if no data is found, set the noDataFound flag
          if (response.data.results.length === 0) {
            noDataFound.value = true;
          }
        }
      });
    };

    const potentialCustomerDelete = async (id: number) => {
      const deleteFunc = safeAsyncCallAndRaiseError(
        async (...args: Parameters<typeof deletePC>) => {
          const res = await deletePC(...args);
          return res;
        }
      );

      try {
        await deleteFunc(id);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete: " + error);
      }
    };

    return {
      currentItem,
      customers,
      clearCustomers,
      customersList,
      customersRef,
      add,
      get,
      update,
      customersFindBy,
      potentialCustomerDelete,
      clearPotentialCustomer,
      noDataFound,
    };
  }
);

//----------------------------------------------------------------
// Business Logic
//----------------------------------------------------------------

/**
 * convert shape of GetPotentialCustomerIndividualPersons200ResponseResultsInner to PotentialCustomerIndividualListScreenItem
 * @param response
 * @returns PotentialCustomerIndividualListScreenItem
 */
export const convertGetPotentialCustomerIndividualPersons200ResponseResultsInnerToListItem =
  (
    response: GetPotentialCustomerIndividualPersons200ResponseResultsInner
  ): PotentialCustomerIndividualListScreenItem => {
    const addressArray = [
      response.searchHitPerson?.address1,
      response.searchHitPerson?.address2,
      response.searchHitPerson?.address3,
    ];

    const address = _.compact(addressArray).join(" ");
    console.log("address", address);

    const converted: PotentialCustomerIndividualListScreenItem = {
      authorizedFlag: response.authorizedFlag,
      id: response.potentialCustomerId,
      departmentName: response.staff.departmentName,
      // departmentName: "名古屋支店", // getDepartmentAPI & getStaff combo
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      staffNumber: response.staff.staffNumber,
      name: `${response.searchHitPerson?.lastName} ${response.searchHitPerson?.firstName}`,
      potentialCustomerPersonAttribute:
        response.searchHitPerson?.potentialCustomerPersonAttribute,
      potentialCustomerType: response.potentialCustomerType,
      marketName: response.market?.marketName,
      relationship: response.searchHitPerson?.potentialCustomerPersonAttribute,
      potentialCustomerName: `${response.potentialCustomerPerson?.lastName} ${response.potentialCustomerPerson?.firstName}`,
      dateOfBirth: response.searchHitPerson?.dateOfBirth,
      telLast: response.searchHitPerson?.telLast,
      address: !address.includes("undefined") ? address : "",
      createdAt: response.createdAt,
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

/**
 * Convert shape of GetPotentialCustomer200Response to PotentialCustomerIndividualScreenItem
 * @param response
 * @returns
 */
export const convertGetPotentialCustomer200ResponseToScreenItem = (
  response: GetPotentialCustomer200Response
): Partial<PotentialCustomerIndividualScreenItem> => {
  const converted: PotentialCustomerIndividualScreenItem = {
    potentialCustomerId: response.potentialCustomerId,
    createdAt: response.createdAt,
    potentialCustomerIndividual: {
      potentialCustomerType: response.potentialCustomerType,
      potentialCustomerPersonId:
        response.individualPotentialCustomer?.person.potentialCustomerPersonId,
      lastName: response.individualPotentialCustomer?.person.lastName ?? "",
      firstName: response.individualPotentialCustomer?.person.firstName ?? "",
      lastNameKana:
        response.individualPotentialCustomer?.person.lastNameKana ?? "",
      firstNameKana:
        response.individualPotentialCustomer?.person.firstNameKana ?? "",
      dateOfBirth: response.individualPotentialCustomer?.person.dateOfBirth,
      addressInformation: {
        postCode: response.individualPotentialCustomer?.person.postCode,
        address1: response.individualPotentialCustomer?.person.address1,
        address2: response.individualPotentialCustomer?.person.address2,
        address3: response.individualPotentialCustomer?.person.address3,
        address4: response.individualPotentialCustomer?.person.address4,
        address5: response.individualPotentialCustomer?.person.address5,
      },
      tel1: response.individualPotentialCustomer?.person.tel1,
      tel2: response.individualPotentialCustomer?.person.tel2,
      mailAddress: response.individualPotentialCustomer?.person.mailAddress,
    },
    potentialCustomerMeta: {
      potentialCustomerType: response.potentialCustomerType,
      potentialCustomerFirstVisitOn: response.potentialCustomerFirstVisitOn,
      memo: response.memo,
    },
    staff: {
      staffId: response.staffId,
      authorizedStaffIds: response.authorizedStaff,
    },
    family: response.individualPotentialCustomer?.family.map((member) => {
      return {
        potentialCustomerPersonId: member.potentialCustomerPersonId,
        potentialCustomerPersonAttribute:
          member.potentialCustomerPersonAttribute,
        potentialCustomerPersonSortNumber:
          member.potentialCustomerPersonSortNumber,
        lastName: member.lastName,
        firstName: member.firstName,
        lastNameKana: member.lastNameKana,
        firstNameKana: member.firstNameKana,
        dateOfBirth: member.dateOfBirth,
        postCode: member.postCode,
        address1: member.address1,
        address2: member.address2,
        address3: member.address3,
        address4: member.address4,
        address5: member.address5,
        tel1: member.tel1,
        tel2: member.tel2,
        mailAddress: member.mailAddress,
      };
    }),
    market: {
      marketId: response.market?.marketId,
      marketCustomerId: response.market?.marketCustomerId,
      supportUse: response.market?.supportUse ? "support" : "notSupport",
    },
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

// Helper function to validate the screen item's structure
function validateScreenItem(
  screenItem:
    | PotentialCustomerIndividualScreenItem
    | PotentialCustomerCorporationScreenItem,
  isCorporate = false
) {
  const isValidScreenItem = isCorporate
    ? isPotentialCustomerCorporationScreenItem(screenItem)
    : isPotentialCustomerIndividualScreenItem(screenItem);

  if (!isValidScreenItem) throw new Error("Invalid screenItem");

  const validatedArray = validateArrayLength(
    screenItem.staff.authorizedStaffIds,
    0,
    1000,
    "staff"
  ).isValid;
  if (!validatedArray)
    throw new Error(
      "authorizedStaff array must contain between 0 and 1000 items."
    );
}

// Common conversion logic abstracted to handle shared properties between individual and corporation
interface CommonCustomerRequest {
  potentialCustomerType: PotentialCustomerType;
  potentialCustomerFirstVisitOn: string | undefined;
  market?: {
    marketId: number;
    marketCustomerId: string | undefined;
    supportUse: boolean;
  };
  memo: string | undefined;
  staffId: number;
  authorizedStaff: number[];
  [key: string]: unknown;
}

function flattenAddressInformation(
  data:
    | PotentialCustomerIndividualScreenItem["potentialCustomerIndividual"]
    | PotentialCustomerCorporationScreenItem["potentialCustomerCorporation"]
    | undefined
) {
  if (!data) return {};
  // flatten addressInformation and remove it
  const { addressInformation, ...rest } = data;

  return {
    ...rest,
    ...addressInformation,
  };
}

function commonConversionLogic(
  screenItem:
    | PotentialCustomerIndividualScreenItem
    | PotentialCustomerCorporationScreenItem,
  includePersonId: boolean,
  isCorporate = false
) {
  const personOrCorporation = includePersonId
    ? {
        ...flattenAddressInformation(
          (screenItem as PotentialCustomerIndividualScreenItem)
            .potentialCustomerIndividual
        ),
        ...flattenAddressInformation(
          (screenItem as PotentialCustomerCorporationScreenItem)
            .potentialCustomerCorporation
        ),
      }
    : {
        ...flattenAddressInformation(
          (screenItem as PotentialCustomerIndividualScreenItem)
            .potentialCustomerIndividual
        ),
        ...flattenAddressInformation(
          (screenItem as PotentialCustomerCorporationScreenItem)
            .potentialCustomerCorporation
        ),
        potentialCustomerPersonId: undefined,
      };

  const commonConverted: CommonCustomerRequest = {
    potentialCustomerType: screenItem.potentialCustomerMeta
      ?.potentialCustomerType as PotentialCustomerType,
    potentialCustomerFirstVisitOn:
      screenItem.potentialCustomerMeta?.potentialCustomerFirstVisitOn,
    market: {
      marketId: screenItem.market?.marketId ?? 0,
      marketCustomerId: screenItem.market?.marketCustomerId,
      supportUse: screenItem.market?.supportUse === "support" ?? false,
    },
    memo: screenItem.potentialCustomerMeta?.memo,
    staffId: screenItem.staff.staffId ?? 0,
    authorizedStaff: screenItem.staff?.authorizedStaffIds ?? [],
  };

  // remove market info if the potentialCustomerType is neither "market" nor "jointRecruitment"
  if (
    shouldRemoveMarketInfo(
      screenItem.potentialCustomerMeta?.potentialCustomerType
    )
  ) {
    delete commonConverted.market;
  }

  if (isCorporate) {
    commonConverted.corporatePotentialCustomer = personOrCorporation;
  } else {
    commonConverted.individualPotentialCustomer = {
      person: personOrCorporation,
      family:
        (screenItem as PotentialCustomerIndividualScreenItem).family?.map(
          (member) => ({
            ...member,
            potentialCustomerPersonId: includePersonId
              ? member.potentialCustomerPersonId
              : undefined,
          })
        ) ?? [],
    };
  }

  return commonConverted;
}

// Finalization process for converted object to remove undefined properties
function finalizeConversion(converted: any, isCorporate = false) {
  if (!isCorporate) {
    const target = converted.individualPotentialCustomer?.family;

    if (target && target.length > 0) {
      target.forEach((member: any, index: number) => {
        const { potentialCustomerPersonId, ...rest } = member;
        target[index] = rest;
      });
    }
  }

  return removeUndefinedAndEmptyStringFromObject(converted);
}

// Refactored function for individual screen items to PUT request
export const convertScreenItemToPutPotentialCustomerRequest = (
  screenItem:
    | PotentialCustomerIndividualScreenItem
    | PotentialCustomerCorporationScreenItem,
  isCorporate = false
): PutPotentialCustomerRequest => {
  validateScreenItem(screenItem, isCorporate);

  const converted = commonConversionLogic(screenItem, true, isCorporate);
  return removeUndefinedAndEmptyStringFromObject(
    converted
  ) as PutPotentialCustomerRequest;
};

// Refactored function for individual screen items to POST request
export const convertScreenItemToPostPotentialCustomerRequest = (
  screenItem:
    | PotentialCustomerIndividualScreenItem
    | PotentialCustomerCorporationScreenItem,
  isCorporate = false
): PostPotentialCustomerRequest => {
  validateScreenItem(screenItem, isCorporate);

  const baseConverted = commonConversionLogic(screenItem, false, isCorporate);

  // Adjust based on the type of screen item
  const potentialCustomerAttribute = isCorporate ? "corporation" : "individual";
  const converted: PostPotentialCustomerRequest = {
    ...baseConverted,
    potentialCustomerAttribute, // dynamically set based on the screen item type
  };

  return finalizeConversion(
    converted,
    isCorporate
  ) as PostPotentialCustomerRequest;
};

// Example usage
// You would call these functions with your screenItem data when converting
// For corporate:
// convertScreenItemToPostPotentialCustomerRequest(screenItem, true);
// For individual:
// convertScreenItemToPostPotentialCustomerRequest(screenItem);

/**
 * call this function to check if the screenItem is valid
 * @param formData
 * @returns
 */
export const isPotentialCustomerIndividualScreenItem = (
  formData: Record<string, any>
): formData is PotentialCustomerIndividualScreenItem => {
  // test if all of the required fields are present
  let ret = false;

  // if any of the required fields are missing, return false
  try {
    ret =
      formData.potentialCustomerIndividual.lastName &&
      formData.potentialCustomerIndividual.firstName &&
      formData.potentialCustomerIndividual.firstNameKana &&
      formData.potentialCustomerIndividual.lastNameKana &&
      formData.potentialCustomerMeta.potentialCustomerType;
    // formData.staff.staffId;
    // formData.staff.authorizedStaffIds &&
    // formData.family.length >= 0 &&
    // formData.market.marketId &&
    // formData.market.supportUse;
  } catch {
    return false;
  }
  return !!ret;
};

/**
 * call this function to check if the screenItem is valid
 * @param formData
 * @returns
 */
export const isPotentialCustomerCorporationScreenItem = (
  formData: Record<string, any>
): formData is PotentialCustomerCorporationScreenItem => {
  // test if all of the required fields are present
  let ret = false;

  // if any of the required fields are missing, return false
  try {
    ret = formData.potentialCustomerCorporation.corporationName;
    // formData.employee.lastNameKana &&
    // formData.employee.firstNameKana;
    // formData.staff.authorizedStaffIds &&
    // formData.family.length >= 0 &&
    // formData.market.marketId &&
    // formData.market.supportUse;
  } catch {
    return false;
  }
  return !!ret;
};
