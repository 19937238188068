import { ref } from "vue";
import { debounce } from "lodash-es";
import { useStaffQueryService } from "@/services/use-staff-query-service";
import { type FormKitNode } from "@formkit/core";
import type { GetStaffCurrent200Response } from "@/api";

export interface StaffInfo {
  staffId: number;
  staffNumber: string;
  lastName: string;
  firstName: string;
  staffFullName: string;
}

export function useAutocompleteLogic() {
  const searchResults = ref<StaffInfo[]>([]);
  const currentEmployee = ref<StaffInfo | "clear" | null>(null);
  const loading = ref(false);
  const error = ref<string | null>(null);
  const authorizedStaffList = ref<StaffInfo[]>([]);

  const { findBy, findByCurrent } = useStaffQueryService();

  async function getStaffData(query: string) {
    if (!query) {
      searchResults.value = [];
      return;
    }
    try {
      loading.value = true;
      const response = await findBy(1, 1000, query);
      searchResults.value = response?.results.map(mapStaffData) ?? [];
      error.value = null;
    } catch (err) {
      error.value = err;
      searchResults.value = [];
    } finally {
      loading.value = false;
    }
  }

  function mapStaffData(item) {
    return {
      staffId: item.staffId,
      staffNumber: item.staffNumber,
      lastName: item.lastName,
      firstName: item.firstName,
      staffFullName: `${item.lastName} ${item.firstName}`,
    };
  }

  function mapStaffDataFromGetStaffCurrent200Response(
    item: GetStaffCurrent200Response
  ) {
    return {
      staffId: item.staffId,
      staffNumber: item.staffNumber,
      lastName: item.personal.lastName,
      firstName: item.personal.firstName,
      staffFullName: `${item.personal.lastName} ${item.personal.firstName}`,
    };
  }

  function selectEmployee(staff: StaffInfo, node: FormKitNode) {
    currentEmployee.value = staff;
    searchResults.value = []; // Clear search results after selection

    // set the value of the input field to the selected employee's staffId
    node.input(staff.staffId);
  }

  // Load staff data using the passed staffId
  async function loadStaffDataById(staffId: number, node: FormKitNode) {
    try {
      const response = await findByCurrent(staffId);

      if (!response) {
        console.error("No data found for the staffId: ", staffId);
        return;
      }

      // return if the previous staffId is the same as the current staffId
      if (
        currentEmployee.value !== "clear" &&
        currentEmployee.value?.staffId === staffId
      ) {
        return;
      }
      node.input(staffId);
      currentEmployee.value =
        mapStaffDataFromGetStaffCurrent200Response(response);
      error.value = null;
    } catch (err) {
      console.error("Error while fetching staff data: ", err);
    } finally {
      // noop
    }
  }

  // get staff data by staffId
  async function getStaffDataById(staffId: number) {
    try {
      const response = await findByCurrent(staffId);

      if (!response) {
        console.error("No data found for the staffId: ", staffId);
        return;
      }

      const staff = mapStaffDataFromGetStaffCurrent200Response(response);
      return staff;
    } catch (err) {
      // noop
    }
  }

  function clearCurrentEmployeeAndTheirId(node: FormKitNode) {
    currentEmployee.value = null;
    node.input(undefined);
  }
  function clearOnlyCurrentEmployee() {
    currentEmployee.value = null;
  }

  async function addAuthorizedStaff(node: FormKitNode, staff: StaffInfo) {
    try {
      const newStaff = await getStaffDataById(staff.staffId);

      if (!newStaff) {
        console.error("No data found for the staffId: ", staff.staffId);
        return;
      }

      // Check if staff already exists in the list to avoid duplicates
      const existingIndex = authorizedStaffList.value.findIndex(
        (s) => s.staffId === newStaff.staffId
      );
      if (existingIndex === -1) {
        authorizedStaffList.value.push(newStaff);
        node.input(authorizedStaffList.value.map((s) => s.staffId)); // Optionally update node with list of staff IDs
      } else {
        console.log("Staff already added");
      }
      currentEmployee.value = "clear";

      // Clear search results after selection
      searchResults.value = [];
    } catch (err) {
      console.error("Error while fetching staff data: ", err);
    }
  }

  async function loadAuthorizedStaffId(node: FormKitNode, staffIds: number[]) {
    // Load authorized staff data using the passed staffIds
    node.input(staffIds);

    // Load authorized staff detail list using the passed staffIds
    authorizedStaffList.value = (
      await Promise.all(
        staffIds.map(async (id) => {
          return await getStaffDataById(id);
        })
      )
    ).filter((staff) => staff !== undefined);
  }

  function removeAuthorizedStaff(node: FormKitNode, staff: StaffInfo) {
    const index = authorizedStaffList.value
      .map((s) => s.staffId)
      .indexOf(staff.staffId);
    if (index > -1) {
      authorizedStaffList.value.splice(index, 1);
    }
    node.input(authorizedStaffList.value.map((s) => s.staffId));
  }

  return {
    searchResults,
    currentEmployee,
    loading,
    error,
    getStaffData: debounce(getStaffData, 500),
    selectEmployee,
    clearCurrentEmployeeAndTheirId,
    loadStaffDataById,
    authorizedStaffList,
    addAuthorizedStaff,
    removeAuthorizedStaff,
    loadAuthorizedStaffId,
    clearOnlyCurrentEmployee,
  };
}
