import * as _ from "lodash-es";

interface KanaMap {
  [key: string]: string;
}

/**
 * Mapping table from full-width Katakana to half-width Katakana.
 */
const fullWidthToHalfWidthKatakanaMap: KanaMap = {
  ア: "ｱ",
  イ: "ｲ",
  ウ: "ｳ",
  エ: "ｴ",
  オ: "ｵ",
  カ: "ｶ",
  キ: "ｷ",
  ク: "ｸ",
  ケ: "ｹ",
  コ: "ｺ",
  サ: "ｻ",
  シ: "ｼ",
  ス: "ｽ",
  セ: "ｾ",
  ソ: "ｿ",
  タ: "ﾀ",
  チ: "ﾁ",
  ツ: "ﾂ",
  テ: "ﾃ",
  ト: "ﾄ",
  ナ: "ﾅ",
  ニ: "ﾆ",
  ヌ: "ﾇ",
  ネ: "ﾈ",
  ノ: "ﾉ",
  ハ: "ﾊ",
  ヒ: "ﾋ",
  フ: "ﾌ",
  ヘ: "ﾍ",
  ホ: "ﾎ",
  マ: "ﾏ",
  ミ: "ﾐ",
  ム: "ﾑ",
  メ: "ﾒ",
  モ: "ﾓ",
  ヤ: "ﾔ",
  ユ: "ﾕ",
  ヨ: "ﾖ",
  ラ: "ﾗ",
  リ: "ﾘ",
  ル: "ﾙ",
  レ: "ﾚ",
  ロ: "ﾛ",
  ワ: "ﾜ",
  ヲ: "ｦ",
  ン: "ﾝ",
  ガ: "ｶﾞ",
  ギ: "ｷﾞ",
  グ: "ｸﾞ",
  ゲ: "ｹﾞ",
  ゴ: "ｺﾞ",
  ザ: "ｻﾞ",
  ジ: "ｼﾞ",
  ズ: "ｽﾞ",
  ゼ: "ｾﾞ",
  ゾ: "ｿﾞ",
  ダ: "ﾀﾞ",
  ヂ: "ﾁﾞ",
  ヅ: "ﾂﾞ",
  デ: "ﾃﾞ",
  ド: "ﾄﾞ",
  バ: "ﾊﾞ",
  ビ: "ﾋﾞ",
  ブ: "ﾌﾞ",
  ベ: "ﾍﾞ",
  ボ: "ﾎﾞ",
  パ: "ﾊﾟ",
  ピ: "ﾋﾟ",
  プ: "ﾌﾟ",
  ペ: "ﾍﾟ",
  ポ: "ﾎﾟ",
  ァ: "ｧ",
  ィ: "ｨ",
  ゥ: "ｩ",
  ェ: "ｪ",
  ォ: "ｫ",
  ッ: "ｯ",
  ャ: "ｬ",
  ュ: "ｭ",
  ョ: "ｮ",
  ー: "ｰ",
  ヴ: "ｳﾞ",
  ヵ: "ｶ",
  ヶ: "ｹ",
  "。": "｡",
  "、": "､",
  "「": "｢",
  "」": "｣",
  "・": "･",
};

/**
 * Converts full-width ASCII characters and symbols to half-width.
 * Except for full-width asterisk (U+FF0A) and full-width hyphen-minus (U+FF0D).
 *
 * @param str - The input string containing full-width and/or half-width characters.
 * @returns A string with full-width ASCII characters and symbols converted to half-width.
 */

export function zenkakuToHankakuAscii(str: string): string {
  if (!str) return "";

  // Updated regex to include 'ー' (U+30FC) and '−' (U+2212)
  return str.replace(/[！-～ー−]/g, (s) => {
    switch (s) {
      case "＊": // Full-width asterisk (U+FF0A)
        return "*";
      case "ー": // Full-width prolonged sound mark (U+30FC)
      case "－": // Full-width hyphen-minus (U+FF0D)
      case "−": // Minus sign (U+2212)
        return "-";
      default: {
        const code = s.charCodeAt(0);
        // Check if the character is a full-width alphanumeric character
        if (
          (code >= 0xff10 && code <= 0xff19) || // 0-9
          (code >= 0xff21 && code <= 0xff3a) || // A-Z
          (code >= 0xff41 && code <= 0xff5a) // a-z
        ) {
          return String.fromCharCode(code - 0xfee0);
        }
        // Keep other full-width symbols unchanged
        return s;
      }
    }
  });
}

/**
 * Converts full-width Katakana characters to half-width Katakana.
 *
 * @param str - The input string containing full-width Katakana.
 * @returns A string with full-width Katakana converted to half-width Katakana.
 */
export function zenkakuToHankakuKatakana(str: string): string {
  if (!str) return "";

  return str.replace(/[\u30A1-\u30FA]/g, (s) => {
    return fullWidthToHalfWidthKatakanaMap[s] || s;
  });
}

/**
 * Removes unwanted characters from the input string.
 * Retains only half-width Katakana, alphanumerics.
 *
 * @param str - The input string after conversion.
 * @returns A string with unwanted characters removed.
 */
export function removeUnwantedCharacters(str: string): string {
  if (!str) return "";

  // Step 1: Remove leading and trailing spaces (both full-width and half-width) and tabs
  const trimmedStr = str.replace(/^[\s\u3000]+|[\s\u3000]+$/g, "");

  // Step 2: Remove all newline characters (\n and \r) from the string
  const noNewlineStr = trimmedStr.replace(/[\n\r]/g, "");

  // Step 3: Remove unwanted characters, preserving allowed characters and internal spaces/tabs
  // Allowed characters:
  // - 0-9A-Za-z: Half-width alphanumerics
  // - ｦ-ﾟ: Half-width Katakana
  // - !-/:-@[-`{-~: Half-width symbols
  // - * and -: Half-width asterisk (*) and hyphen (-)
  // - \uFF10-\uFF19: Full-width digits ０-９
  // - \uFF21-\uFF3A: Full-width English uppercase letters Ａ-Ｚ
  // - \uFF41-\uFF5A: Full-width English lowercase letters ａ-ｚ
  // - \uFF01-\uFF0D: Full-width symbols (specific range)
  // - \uFF0F-\uFFEF: Other full-width symbols
  // - \u3040-\u309F: Hiragana
  // - \u30A0-\u30FF: Katakana
  // - \u4E00-\u9FFF: Kanji
  // - \u3000: Full-width space
  // - \t: Half-width tab
  // - \u2212: Minus sign (−)

  const regex =
    /[^0-9A-Za-zｦ-ﾟ!-/:-@[-`{-~*\-\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A\uFF01-\uFF0D\uFF0F-\uFFEF\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\u3000 \t\u2212]/g;

  return noNewlineStr.replace(regex, "");
}

/**
 * Converts full-width characters to half-width and removes unwanted full-width characters.
 *
 * @param str - The input string containing full-width and/or half-width characters.
 * @returns A string with full-width characters converted to half-width and unwanted characters removed.
 */
export function zenkaku2HankakuAndRidOfZenkakuComposition(str: string): string {
  return removeUnwantedCharacters(
    zenkakuToHankakuKatakana(zenkakuToHankakuAscii(str))
  );
}

/**
 * Formats a given number by inserting commas as thousand separators.
 */
export function formatAmount(amount: number): string {
  if (amount != null && amount !== "") {
    return Number(amount).toLocaleString();
  }
  return "";
}

/**
 * Determines the CSS class based on the amount value.
 */
export function amountClass(amount: number): string {
  if (amount < 0) {
    return "text-red text-right";
  }
  return "text-right";
}

/**
 * マッピングテーブルとキーを使って型安全に値を取得する
 *
 * Get a value typing-safely taking a mapping table and  a key as parameters.
 *
 * @export
 * @template O
 * @template K
 * @param {O} o
 * @param {K} k
 * @return {*}
 */
export function getDisplayNameOf<
  O extends { [key: string]: unknown },
  K extends keyof O | undefined
>(o: O, k: K) {
  if (k === undefined) return undefined;

  return o[k];
}

export const isInsuranceType = (
  val: string
): val is "lifeInsurance" | "nonLifeInsurance" => {
  let result = false;

  if (val === "lifeInsurance" || val === "nonLifeInsurance") {
    result = true;
  } else {
    console.log("Error: Invalid insuranceType", val);
  }
  return result;
};

export const isContractorType = (
  val: string
): val is "individual" | "corporation" => {
  let result = false;

  if (val === "individual" || val === "corporation") {
    result = true;
  } else {
    console.log("Error: Invalid contractorType", val);
  }
  return result;
};

export function getTodaysDate() {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const todaysDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  return todaysDate;
}

// dateUtils.ts
/**
 * Returns a date string representing one day prior to the specified base date.
 * @param baseDate - The base date in the format of "YYYY-MM-DD".
 * @returns A date string in the format of "YYYY-MM-DD".
 */
export function getOneDayAgoFrom(baseDate: string): string {
  return getDateFrom(baseDate, -1);
}

// this function adds 1 day to the previous boss's start date.
// this prevent the very rare case where a previous boss's end date
// and start date will overlap.
export function addDaysToDate(baseDate: string): string {
  return getDateFrom(baseDate, 1);
}

export function getDateFrom(baseDate: string, delta: number): string {
  const dateParts = baseDate.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript
  const day = parseInt(dateParts[2], 10);

  const dateObject = new Date(year, month, day);
  dateObject.setDate(dateObject.getDate() + delta);

  const newYear = dateObject.getFullYear();
  const newMonth = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const newDay = dateObject.getDate().toString().padStart(2, "0");

  return `${newYear}-${newMonth}-${newDay}`;
}

export function exceptYearFromDate(date: string): string {
  if (date.length === 0) {
    return "";
  }

  const dateParts = date.split("-");

  const dateObject = new Date(
    parseInt(dateParts[0], 10),
    parseInt(dateParts[1], 10) - 1,
    parseInt(dateParts[2], 10)
  );

  const month = (dateObject.getMonth() + 1).toString();
  const day = dateObject.getDate().toString();

  return `${month}月${day}日`;
}

const enumValueObject = {
  staffRoleType: {
    admin: "管理者",
    normal: "一般",
  },
  sex: {
    male: "男性",
    female: "女性",
  },
  insuranceCategory: {
    lifeInsurance: "生保",
    nonLifeInsurance: "損保",
  },
  paymentMethod: {
    monthlyPayment: "月払",
    semiAnnualPayment: "半年払",
    annualPayment: "年払",
    oneTimePayment: "一時払",
    prepayment: "全期前納",
  },
  nonLifeInsuranceAccountingCategory: {
    new: "新規",
    renewal: "更改",
    transfer: "異動",
    unknown: "不明",
  },
  nonLifeInsuranceDepositCategory: {
    directDebit: "代勘口座",
    agencyAccount: "口振",
    creditCard: "クレカ",
    salaryDeduction: "給与天引",
    paymentSlip: "払込票払",
    unknown: "不明",
  },
  potentialCustomerAttribute: {
    corporation: "法人",
    individual: "個人",
  },
  potentialCustomerType: {
    myself: "自己開拓",
    market: "マーケット購入",
    jointRecruitment: "マーケット共同募集",
  },
  potentialCustomerPersonAttribute: {
    themself: "本人",
    partner: "配偶者",
    child: "子供",
    other: "その他",
  },
  adjustmentDeductionTypeAttribute: {
    adjustment: "調整額",
    deduction: "控除額",
    deductionForValuables: "有価品控除額",
    marketDeduction: "マーケット控除額",
  },
  commissionCategory: {
    commission: "手数料",
    incentive: "インセンティブ",
  },
  commissionImportStatus: {
    commissionNotCalculated: "手数料未計算",
    commissionCalculated: "手数料計算済",
    performanceBasedPayCalculated: "業績給計算済",
    deleted: "削除",
  },
  fpQualification: {
    noQualification: "資格なし",
    firstClass: "1級",
    secondClass: "2級",
    thirdClass: "3級",
  },
  securitiesSalespersonQualification: {
    noQualification: "資格なし",
    firstClass: "一種",
    secondClass: "二種",
  },
  commissionFormatColumn: {
    policyNumberColumn: "証券番号",
    contractorColumn: "契約者",
    insuredColumn: "被保険者",
    commissionColumn: "手数料",
    staffColumn: "担当者",
    staffCodeColumn: "担当者コード",
    insuranceTypeColumn: "保険種類",
    contractDateColumn: "契約日",
    insuranceFeeColumn: "保険料",
  },
  firstYearFlag: {
    true: "初年度",
    false: "継続",
  },
  apiName: {
    PostStaff: "PostStaff",
    PutStaff: "PutStaff",
    PutStaffPassword: "PutStaffPassword",
    PutStaffAccountActive: "PutStaffAccountActive",
    PostStaffStandardMonthlyRemunerationCsv:
      "PostStaffStandardMonthlyRemunerationCsv",
    PostMarket: "PostMarket",
    PutMarket: "PutMarket",
    PostInsuranceCompany: "PostInsuranceCompany",
    PutInsuranceCompany: "PutInsuranceCompany",
    PostInsuranceType: "PostInsuranceType",
    PutInsuranceType: "PutInsuranceType",
    PostPotentialCustomer: "PostPotentialCustomer",
    PutPotentialCustomer: "PutPotentialCustomer",
    DeletePotentialCustomer: "DeletePotentialCustomer",
    PostDepartment: "PostDepartment",
    PutDepartment: "PutDepartment",
    PostAdjustmentDeduction: "PostAdjustmentDeduction",
    PutAdjustmentDeduction: "PutAdjustmentDeduction",
    DeleteAdjustmentDeduction: "DeleteAdjustmentDeduction",
    GetAdjustmentDeductionCsv: "GetAdjustmentDeductionCsv",
    PostAdjustmentDeductionCsv: "PostAdjustmentDeductionCsv",
    PostAdjustmentDeductionIncentiveCsv: "PostAdjustmentDeductionIncentiveCsv",
    PostAdjustmentDeductionType: "PostAdjustmentDeductionType",
    PutAdjustmentDeductionType: "PutAdjustmentDeductionType",
    PostContractReport: "PostContractReport",
    PutContractReport: "PutContractReport",
    DeleteContractReport: "DeleteContractReport",
    GetContractReportLifeInsuranceCsv: "GetContractReportLifeInsuranceCsv",
    GetContractReportNonLifeInsuranceCsv:
      "GetContractReportNonLifeInsuranceCsv",
    PostSecurities: "PostSecurities",
    PutSecurities: "PutSecurities",
    DeleteSecurities: "DeleteSecurities",
    PostSecuritiesCommissionMatching: "PostSecuritiesCommissionMatching",
    PutSecuritiesCommissionMatching: "PutSecuritiesCommissionMatching",
    GetSecuritiesCsv: "GetSecuritiesCsv",
    PostSecuritiesCsv: "PostSecuritiesCsv",
    PostSecuritiesShareCsv: "PostSecuritiesShareCsv",
    PutCommissionFormat: "PutCommissionFormat",
    PostCommissionCsv: "PostCommissionCsv",
    GetCommissionsCsv: "GetCommissionsCsv",
    DeleteCommissionImport: "DeleteCommissionImport",
    PutCommissionCalculation: "PutCommissionCalculation",
    GetCommissionCalculationResultsCsv: "GetCommissionCalculationResultsCsv",
    PutPerformanceBasedPayCalculation: "PutPerformanceBasedPayCalculation",
    PostPurchasePotentialCustomer: "PostPurchasePotentialCustomer",
    PutPurchasePotentialCustomer: "PutPurchasePotentialCustomer",
    PostPayrollCsv: "PostPayrollCsv",
    PutStaffMap: "PutStaffMap",
    DeleteStaffMap: "DeleteStaffMap",
    GetPerformanceBasedPayPayrollCsv: "GetPerformanceBasedPayPayrollCsv",
  },
  contractReportSort: {
    contractReportOn: "申達報告日",
    staff: "担当募集人",
    insuranceCompany: "保険会社",
    market: "マーケット",
  },
};

// オブジェクトの型定義
interface EnumValueObject {
  [category: string]: {
    [key: string]: string;
  };
}

// ジェネリクスを使用した関数定義
export function getValueFromEnum<
  T extends keyof EnumValueObject,
  K extends keyof EnumValueObject[T]
>(
  category: T,
  key: K,
  object: EnumValueObject = enumValueObject
): EnumValueObject[T][K] {
  return object[category][key];
}

export function generateOptionsFromEnum(category: string, values: string[]) {
  return values.map((value) => ({
    label: getValueFromEnum(category, value),
    value,
  }));
}

// unique error message to be used with Formkit
export const dateAfterErrorMessage = (rawDate: string) => {
  const date = rawDate.split("-");
  const year = date[0];
  const month = date[1];
  const day = date[2];
  return `*開始日は${year}年${month}月${day}日より後の日付である必要があります。`;
};

export const validatePassword = (password: string): boolean => {
  // This regular expression uses positive lookaheads to ensure that the password meets the criteria
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  return passwordRegex.test(password);
};

// Load content from screens.yaml and store it as an array of Screen type
import { screens, type Screen } from "@/views/screens";
/**
 * A function that returns a function that checks if a screen object has a matching id.
 * @param id - The id to match against.
 * @returns A function that takes a screen object and returns a boolean indicating if the id matches.
 */
const findScreenById = (id: number) => (screen: Screen) => screen.id === id;

/**
 * A function that returns a function that retrieves a mode value from a screen object.
 * @param modeKey - The key of the mode value to retrieve.
 * @returns A function that takes a screen object and returns the mode value associated with the given key.
 */
const getModeValue = (modeKey: string) => (screen: Screen) =>
  screen.mode[modeKey] || null;

/**
 * A function that generates a label for a screen object and mode value.
 * @param screen - The screen object to generate a label for.
 * @param modeValue - The mode value to include in the label.
 * @param insuranceCategory - The insurance category to include in the label (optional).
 * @param potentialCustomerAttribute - The potential customer attribute to include in the label (optional).
 * @returns A string representing the label for the screen object and mode value.
 */
const getLabel = (
  screen: Screen,
  modeValue: string | null,
  insuranceCategory: string | null,
  potentialCustomerAttribute: string | null
) => {
  const labelName = screen.labelName;
  if (insuranceCategory && potentialCustomerAttribute) {
    return `${insuranceCategory}${labelName}(${potentialCustomerAttribute}): ${modeValue}`;
  }
  if (insuranceCategory && !potentialCustomerAttribute) {
    return `${insuranceCategory}${labelName}: ${modeValue}`;
  }
  return modeValue === null ? labelName : `${labelName}: ${modeValue}`;
};

/**
 * A function that retrieves the label and mode value for a screen object.
 * @param id - The id of the screen object to retrieve.
 * @param modeKey - The key of the mode value to retrieve.
 * @param insuranceCategory - The insurance category to include in the label (optional).
 * @param potentialCustomerAttribute - The potential customer attribute to include in the label (optional).
 * @returns A string representing the label and mode value for the screen object.
 * @throws An error if no screen object is found with the given id or if no mode value is found with the given key.
 */
export const getLabelAndMode = (
  id: number,
  modeKey: string,
  insuranceCategory: string | null = null,
  potentialCustomerAttribute: string | null = null
): string => {
  const screen = screens.find(findScreenById(id));
  if (!screen) {
    throw new Error(`No screen found with id: ${id}`);
  }
  const modeValue = getModeValue(modeKey)(screen);

  return getLabel(
    screen,
    modeValue,
    insuranceCategory,
    potentialCustomerAttribute
  );
};

/**
 * This function deep clones the input object and recursively removes keys whose values are undefined, null or empty strings.
 * The resulting object does not contain any keys with these values.
 *
 * @export
 * @param {any} obj - The input object that needs cleaning.
 * @returns {any} The cloned object that has been cleaned of keys with undefined, null or empty string values.
 */
export const removeUndefinedAndEmptyStringFromObject = (obj: any): any => {
  const clonedObj = _.cloneDeep(obj);
  return _.transform(clonedObj, (result, value, key) => {
    if (value !== undefined && value !== null && value !== "") {
      const cleanedValue =
        _.isObject(value) || _.isArray(value)
          ? removeUndefinedAndEmptyStringFromObject(value)
          : value;
      if (
        !(_.isObject(cleanedValue) || _.isArray(cleanedValue)) ||
        !_.isEmpty(cleanedValue)
      )
        result[key] = cleanedValue;
    }
  });
};

/**
 * A function that confirms an array fits the length parameters.
 * @param array - the array being checked
 * @param min - The minimum number of items in the array
 * @param max - The maximum number of items in the array
 * @returns An object with two properties: 'isValid' (boolean) and 'errorMessage' (string)
 */
export function validateArrayLength(
  array: unknown[] | undefined,
  min: number,
  max: number,
  item: string
): { isValid: boolean; errorMessage: string | undefined } {
  if (array === undefined || array.length === 0) {
    // If it's undefined or empty, return true
    return { isValid: true, errorMessage: undefined };
  }

  if (!Array.isArray(array)) {
    // If it's not an array, return false
    return { isValid: false, errorMessage: "Invalid array format" };
  }

  const minLength = min;
  const maxLength = max;

  // otherwise, return true/false, and (if false) an error message.
  if (array.length < minLength) {
    return {
      isValid: false,
      errorMessage: `${item}は${min}件以上登録してください`,
    };
  }

  if (array.length > maxLength) {
    return {
      isValid: false,
      errorMessage: `${item}は${max}件以上登録できません`,
    };
  }

  if (array.length === maxLength) {
    return {
      isValid: true,
      errorMessage: `${item}の上限${max}件に達しました`,
    };
  }

  return { isValid: true, errorMessage: undefined };
}

type AsyncFunctionWithArgs<R, Args extends any[]> = (
  ...args: Args
) => Promise<R>;
type ErrorCallback<R> = (error: any) => Promise<R>;

/**
 * Wrap the given function with a try/catch block.
 * @param fn
 * @param errorCallback
 * @returns
 */
export const safeAsyncCall = <R, Args extends any[]>(
  fn: AsyncFunctionWithArgs<R, Args>,
  errorCallback: ErrorCallback<R | null> = (error) => {
    console.error(
      "safeAsyncCall captured this error and returned tentative value(null as default):",
      error
    );
    return Promise.resolve(null);
  }
): AsyncFunctionWithArgs<R | null, Args> => {
  return async (...args: Args): Promise<R | null> => {
    try {
      return await fn(...args);
    } catch (error) {
      return await errorCallback(error);
    }
  };
};

export const safeAsyncCallAndRaiseError = <R, Args extends any[]>(
  fn: AsyncFunctionWithArgs<R, Args>
) => {
  return safeAsyncCall(fn, (error) => {
    console.error(
      "safeAsyncCallAndRaiseError captured this error and bubble it up to the caller.",
      error
    );

    return Promise.reject(error);
  });
};

/**
 * Wraps an async function and replaces its falsy argument values with `undefined`, except for `false`.
 *
 * @template R - The return type of the async function.
 * @template Args - The types of the arguments for the async function as a tuple.
 * @param {AsyncFunctionWithArgs<R, Args>} fn - The async function to be wrapped.
 *
 * @returns {AsyncFunctionWithArgs<R, Args>} - A new async function that takes the same arguments and returns the same type as the original function, but with falsy argument values replaced by `undefined`.
 */
export const mapFalsyToUndefinedAndCall = <R, Args extends any[]>(
  fn: AsyncFunctionWithArgs<R, Args>
): AsyncFunctionWithArgs<R, Args> => {
  return async (...args: Args): Promise<R> => {
    // Replace falsy values except for `false` with undefined
    const modifiedArgs = args.map((elem) =>
      elem || elem === false ? elem : undefined
    ) as Args;
    const result = await fn(...modifiedArgs);
    return result;
  };
};

// check that no two values in an object are duplicates
export function checkForDuplicates(selectValues: Record<string, any>): boolean {
  // get all the values from the object
  const valuesSet = new Set<string>();
  const valuesArray = Object.values(selectValues);

  // if any existing values match, return true
  for (const value of valuesArray) {
    if (value !== "" && value !== undefined) {
      if (valuesSet.has(value)) {
        return true;
      }
      valuesSet.add(value);
    }
  }
  // if there are no duplicates, return false
  return false;
}

/**
 * Maps response values to be used in dropdown select
 */

export const getOptionsForSelectbox = (
  data: any[],
  label: string,
  value: string
) => {
  return data.map((item) => {
    return {
      label: item[label],
      value: item[value],
    };
  });
};

// A event listener to check the populated value and return the integer value from the input
export const getIntegerValue = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  const value = target.value;
  // if the value is non-numeric, return an empty string
  if (value && isNaN(parseInt(value))) {
    target.value = "";
  } else if (value) {
    target.value = parseInt(value).toString();
  }
};

// Helper function to create a delay
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
