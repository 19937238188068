import {
  PotentialCustomerQueryApiFactory,
  PotentialCustomerApiFactory,
  type GetPotentialCustomerCorporations200ResponseResultsInner,
  type GetPotentialCustomer200Response,
  type PutPotentialCustomerRequest,
  type PostPotentialCustomerRequest,
} from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  PotentialCustomerCorporationListScreenItem,
  PotentialCustomerCorporationScreenItem,
} from "@/types/PotentialCustomer/potential-customer-corporation";
import { defineStore, storeToRefs } from "pinia";
import { ref, computed, type Ref } from "vue";
import axiosInstance from "@/utils/axios-customized-instance";
import {
  removeUndefinedAndEmptyStringFromObject,
  safeAsyncCallAndRaiseError,
} from "@/utils/helper";

import {
  convertScreenItemToPostPotentialCustomerRequest as postConerter,
  convertScreenItemToPutPotentialCustomerRequest as putConverter,
  isPotentialCustomerCorporationScreenItem as validator,
} from "@/stores/potential-customer-individual";

import _ from "lodash-es";

/**
 * Import partial stores
 */
import { usePotentialCustomerInfoStore } from "@/stores/PotentialCustomerManagement/PotentialCustomerInfo";
import { useMarketInfoStore } from "./PotentialCustomerManagement/MarketInfo";
import { useStaffInfoStore } from "./PotentialCustomerManagement/StaffInfo";
import { usePotentialCustomerCorporationInfoStore } from "./PotentialCustomerManagement/PotentialCustomerCorporation";
const potentialCustomerId = ref<number | undefined>();
const createdAt = ref<string>("");

export const isPotentialCustomerCorporationScreenItem = validator;

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

/**
 * Functions and types generated by OpenAPI Generator
 */
const { getPotentialCustomerCorporations } = PotentialCustomerQueryApiFactory(
  ...args,
  undefined,
  axiosInstance
);
export type ParamsForFindBy = Parameters<
  typeof getPotentialCustomerCorporations
>;

const {
  postPotentialCustomer,
  getPotentialCustomer,
  putPotentialCustomer,
  deletePotentialCustomer: deletePC,
} = PotentialCustomerApiFactory(...args, undefined, axiosInstance);

/**
 * User defined guard function.
 *
 * If the value is not null, it means that the type of
 * the value is Response(Get.....Response["results"]).
 */
const isDataExists = (value: any): value is Response => {
  return value !== null;
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
// ref()s become state properties
// computed()s become getters
// function()s become actions
// https://pinia.vuejs.org/cookbook/composables.html#setup-stores
export const usePotentialCustomerCorporationStore = defineStore(
  "potential-customer-corporation",
  () => {
    // state
    const potentialCustomerCorporation =
      usePotentialCustomerCorporationInfoStore();
    const market = useMarketInfoStore();
    const staff = useStaffInfoStore();
    const potentialCustomer = usePotentialCustomerInfoStore();

    // This ref is used to show the message when no data is found
    const noDataFound = ref<boolean>(false);
    const { currentItem: potentialCustomerCorporationData } = storeToRefs(
      potentialCustomerCorporation
    );
    const { currentItem: marketInfoData } = storeToRefs(market);
    const { currentItem: staffInfoData } = storeToRefs(staff);
    const { currentItem: potentialCustomerInfoData } =
      storeToRefs(potentialCustomer);

    const currentItem: Ref<PotentialCustomerCorporationScreenItem> = ref({
      potentialCustomerId,
      createdAt,
      potentialCustomerAttribute: "corporation",
      potentialCustomerCorporation: potentialCustomerCorporationData,
      staff: staffInfoData,
      market: marketInfoData,
      potentialCustomerMeta: potentialCustomerInfoData,
    });

    const corporations = ref<
      GetPotentialCustomerCorporations200ResponseResultsInner[] | undefined
    >(undefined);

    // getters
    /**
     * The collection for search results
     */
    const corporationsList = computed(() => {
      if (corporations.value === undefined) {
        return undefined;
      }

      const res = corporations.value.map((corporation) => {
        return convertGetPotentialCustomerCorporations200ResponseResultsInnerToListItem(
          corporation
        );
      });
      return res;
    });

    const corporationsRef = computed(() => {
      return corporations;
    });

    // actions
    const clearCorporations = () => {
      corporations.value = undefined;
    };

    const clearPotentialCustomer = () => {
      potentialCustomerCorporation.$reset();
      potentialCustomer.$reset();
      market.$reset();
      staff.$reset();
    };

    const add = async (corporation: PotentialCustomerCorporationScreenItem) => {
      const res = await postPotentialCustomer(
        convertScreenItemToPostPotentialCustomerRequest(
          corporation
        ) as PostPotentialCustomerRequest
      );
      const { data: id } = res;
      return id;
    };

    const get = async (id: number) => {
      try {
        const res = await getPotentialCustomer(id);
        const { data, status } = res;

        if (status === 200)
          return convertGetPotentialCustomer200ResponseToScreenItem(data);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to get: " + error);
      }
    };

    const update = async (customer: PotentialCustomerCorporationScreenItem) => {
      // Error if potentialCustomerId is not defined
      if (!customer.potentialCustomerId) {
        throw new Error("potentialCustomerId is not defined");
      }
      const res = await putPotentialCustomer(
        customer.potentialCustomerId,
        convertScreenItemToPutPotentialCustomerRequest(
          customer
        ) as PutPotentialCustomerRequest
      );
      const { status } = res;
      if (status != 204) throw new Error("Failed to update");
    };

    const corporationsFindBy = async (...args: ParamsForFindBy) => {
      await getPotentialCustomerCorporations(...args).then((response) => {
        // reset noDataFound flag to false
        noDataFound.value = false;
        if (isDataExists(response.data) && response.data.results) {
          corporations.value = response.data.results;

          // set noDataFound flag to true if no data is found
          if (response.data.results.length === 0) {
            noDataFound.value = true;
          }
        }
      });
    };

    const potentialCustomerDelete = async (id: number) => {
      const deleteFunc = safeAsyncCallAndRaiseError(
        async (...args: Parameters<typeof deletePC>) => {
          const res = await deletePC(...args);
          return res;
        }
      );

      try {
        await deleteFunc(id);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete: " + error);
      }
    };

    return {
      currentItem,
      clearCorporations,
      corporationsList,
      corporationsRef,
      add,
      get,
      update,
      corporationsFindBy,
      potentialCustomerDelete,
      clearPotentialCustomer,
      noDataFound,
    };
  }
);

//----------------------------------------------------------------
// Business Logic
//----------------------------------------------------------------

/**
 * convert shape of GetPotentialCustomerCorporations200ResponseResultsInner to PotentialCustomerCorporationListScreenItem
 * @param response
 * @returns PotentialCustomerCorporationListScreenItem
 */
export const convertGetPotentialCustomerCorporations200ResponseResultsInnerToListItem =
  (
    response: GetPotentialCustomerCorporations200ResponseResultsInner
  ): PotentialCustomerCorporationListScreenItem => {
    const addressArray = [
      response.corporation.address1,
      response.corporation.address2,
      response.corporation.address3,
    ];
    const address = _.compact(addressArray).join(" ");

    const converted: PotentialCustomerCorporationListScreenItem = {
      authorizedFlag: response.authorizedFlag,
      id: response.potentialCustomerId,
      createdAt: response.createdAt,
      potentialCustomerType: response.potentialCustomerType,
      departmentName: response.staff.departmentName,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      staffNumber: response.staff.staffNumber,
      marketName: response.market?.marketName,
      corporationName: response.corporation.corporationName,
      telLast: response.corporation.telLast,
      address: !address.includes("undefined") ? address : "",
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

// TODO: Add back with editable screen
// /**
//  * Convert shape of GetPotentialCustomer200Response to PotentialCustomerCorporationScreenItem
//  * @param response
//  * @returns
//  */
export const convertGetPotentialCustomer200ResponseToScreenItem = (
  response: GetPotentialCustomer200Response
): Partial<PotentialCustomerCorporationScreenItem> => {
  const converted: PotentialCustomerCorporationScreenItem = {
    // potentialCustomerId: response.potentialCustomerId,
    createdAt: response.createdAt,
    potentialCustomerAttribute: response.potentialCustomerAttribute,
    potentialCustomerCorporation: {
      potentialCustomerType: response.potentialCustomerType,
      corporationName:
        response.corporatePotentialCustomer?.corporationName ?? "",
      corporationClosingMonth:
        response.corporatePotentialCustomer?.corporationClosingMonth,
      corporationRepresentativeFirstName:
        response.corporatePotentialCustomer
          ?.corporationRepresentativeFirstName ?? "",
      corporationRepresentativeLastName:
        response.corporatePotentialCustomer
          ?.corporationRepresentativeLastName ?? "",
      addressInformation: {
        postCode: response.corporatePotentialCustomer?.postCode ?? "",
        address1: response.corporatePotentialCustomer?.address1 ?? "",
        address2: response.corporatePotentialCustomer?.address2 ?? "",
        address3: response.corporatePotentialCustomer?.address3 ?? "",
        address4: response.corporatePotentialCustomer?.address4 ?? "",
        address5: response.corporatePotentialCustomer?.address5 ?? "",
      },
      tel1: response.corporatePotentialCustomer?.tel1 ?? "",
      tel2: response.corporatePotentialCustomer?.tel2 ?? "",
      mailAddress: response.corporatePotentialCustomer?.mailAddress ?? "",
    },
    staff: {
      staffId: response.staffId,
      authorizedStaffIds: response.authorizedStaff,
    },
    potentialCustomerMeta: {
      potentialCustomerType: response.potentialCustomerType,
      potentialCustomerFirstVisitOn: response.potentialCustomerFirstVisitOn,
      memo: response.memo,
    },
    market: {
      marketId: response.market?.marketId,
      marketCustomerId: response.market?.marketCustomerId,
      supportUse: response.market?.supportUse ? "support" : "notSupport",
    },
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

/**
 * Convert shape of PotentialCustomerCorporationScreenItem to PostPotentialCustomerRequest
 * @param screenItem
 * @returns converted PostPotentialCustomerRequest
 */
export const convertScreenItemToPostPotentialCustomerRequest = (
  screenItem: PotentialCustomerCorporationScreenItem
) => {
  return postConerter(screenItem, true);
};

/**
 * Convert shape of PotentialCustomerCorporationScreenItem to PutPotentialCustomerRequest
 * @param screenItem
 * @returns converted PutPotentialCustomerRequest
 */
export const convertScreenItemToPutPotentialCustomerRequest = (
  screenItem: PotentialCustomerCorporationScreenItem
) => {
  return putConverter(screenItem, true);
};
