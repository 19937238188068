import type {
  GetPotentialCustomer200Response,
  GetPotentialCustomerCorporations200Response,
  GetPotentialCustomerCorporations200ResponseResultsInner,
  GetPotentialCustomerIndividualPersons200Response,
  GetPotentialCustomerIndividualPersons200ResponseResultsInner,
  PostPotentialCustomerRequest,
} from "@/api";
import { rest } from "msw";
import randomInteger from "random-int";
import _ from "lodash-es";
import { useMarketQueryService } from "@/services/use-market-query-service";
import { useStaffService } from "@/services/use-staff-service";
import { useDepartmentService } from "@/services/use-department-service";

import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

// use market ID to get market name
async function getMarketName(id: string) {
  let name = "";
  const results = await useMarketQueryService().findBy(1, 1, id);
  if (results.results[0] == undefined) {
    return "";
  } else {
    {
      name = results.results[0].marketName;
      return name;
    }
  }
}

// receive department name for List Item when editing or creating a record.
// use staff ID to get the department ID.
async function getStaffData(id: number) {
  let staff = undefined;
  const results = await useStaffService().get(id);
  staff = results.data;
  return staff;
}
// use staff ID to get department name
async function getDepartmentName(id: number) {
  const results = await useDepartmentService().get(id);
  const name = results.data.departmentName;
  return name;
}

///////////////////
// Individual
///////////////////

/**
 * Convert json shape from PostPotentialCustomerRequest to GetPotentialCustomer200Response
 * @param params PostPotentialCustomerRequest
 * @returns [
    GetPotentialCustomer200Response,
    GetPotentialCustomerIndividualPersons200Response["results"]
  ]
 */

export const convert = async (params: PostPotentialCustomerRequest) => {
  const newIndividualCustomer = {
    potentialCustomerId: randomInteger(1000000, 9999999),
    potentialCustomerAttribute: params.potentialCustomerAttribute,
    potentialCustomerType: params.potentialCustomerType,
    potentialCustomerFirstVisitOn: params.potentialCustomerFirstVisitOn,
    market: params.market,

    corporatePotentialCustomer: params?.corporatePotentialCustomer,

    individualPotentialCustomer: {
      person: {
        potentialCustomerPersonId: randomInteger(1000000, 9999999),
        ...params.individualPotentialCustomer?.person,
      },
      family: Array.isArray(params.individualPotentialCustomer?.family)
        ? params.individualPotentialCustomer?.family.map((person) => {
            return {
              potentialCustomerPersonId: randomInteger(1000000, 9999999),
              ...person,
            };
          })
        : [],
    },

    memo: params.memo,
    staffId: params.staffId,
    authorizedStaff: params.authorizedStaff,
  };

  const marketName = await getMarketName(
    String(newIndividualCustomer.market?.marketId)
  );
  const staffData = await getStaffData(newIndividualCustomer.staffId);
  //TODO: this does now allow for date, ie. is not checking against current date.
  const departmentId = staffData.departmentAffiliation[0].departmentId;
  const departmentName = await getDepartmentName(Number(departmentId));

  const newInsureds = [
    // Myself
    {
      authorizedFlag: true,
      potentialCustomerId: newIndividualCustomer.potentialCustomerId,

      staff: {
        staffId: params.staffId,
        firstName: staffData.personal.firstName,
        lastName: staffData.personal.lastName,
        //FIXME:
        departmentName: departmentName,
        departmentId: departmentId,
      },
      searchHitPerson: {
        firstName:
          newIndividualCustomer.individualPotentialCustomer.person.firstName,
        lastName:
          newIndividualCustomer.individualPotentialCustomer.person.lastName,
        // potentialCustomerPersonAttribute:
        //   newIndividualCustomer.person.potentialCustomerPersonAttribute,
        dateOfBirth:
          newIndividualCustomer.individualPotentialCustomer.person.dateOfBirth,
        address1:
          newIndividualCustomer.individualPotentialCustomer.person.address1,
        address2:
          newIndividualCustomer.individualPotentialCustomer.person.address2,
        address3:
          newIndividualCustomer.individualPotentialCustomer.person.address3,
        telLast: newIndividualCustomer.individualPotentialCustomer.person.tel1
          ? newIndividualCustomer.individualPotentialCustomer.person.tel1.slice(
              -4
            )
          : null,
      },
      potentialCustomerPerson: {
        firstName:
          newIndividualCustomer.individualPotentialCustomer.person.firstName,
        lastName:
          newIndividualCustomer.individualPotentialCustomer.person.lastName,
      },
      market: {
        marketId: newIndividualCustomer.market?.marketId,
        marketName: marketName,
      },

      potentialCustomerType: params.potentialCustomerType,
    },
    // Family
    ...newIndividualCustomer.individualPotentialCustomer.family.map(
      (newFamily) => {
        return {
          authorizedFlag: true,
          potentialCustomerId: newIndividualCustomer.potentialCustomerId,
          staff: {
            staffId: params.staffId,
            firstName: staffData.personal.firstName,
            lastName: staffData.personal.lastName,
            departmentName: departmentName,
            departmentId: departmentId,
          },
          searchHitPerson: {
            firstName: newFamily.firstName,
            lastName: newFamily.lastName,
            potentialCustomerPersonAttribute:
              newFamily.potentialCustomerPersonAttribute,
            dateOfBirth: newFamily.dateOfBirth,
            address1: newFamily.address1,
            address2: newFamily.address2,
            address3: newFamily.address3,
            telLast: newFamily.tel1 ? newFamily.tel1.slice(-4) : "",
          },
          potentialCustomerPerson: {
            firstName:
              newIndividualCustomer.individualPotentialCustomer.person
                .firstName,
            lastName:
              newIndividualCustomer.individualPotentialCustomer.person.lastName,
          },
          market: {
            marketId: newIndividualCustomer.market?.marketId,

            marketName: marketName,
          },
          potentialCustomerType: params.potentialCustomerType,
        };
      }
    ),
  ];
  return [newIndividualCustomer, newInsureds] as [
    GetPotentialCustomer200Response,
    GetPotentialCustomerIndividualPersons200Response["results"]
  ];
};

///////////////////
// Corporate
///////////////////

/**
 * Convert json shape from PostPotentialCustomerRequest to GetPotentialCustomer200Response
 * @param params PostPotentialCustomerRequest
 * @returns [
    GetPotentialCustomer200Response,
    GetPotentialCustomerCorporationPersons200Response["results"]
  ]
 */
export const convertCorporation = async (
  params: PostPotentialCustomerRequest
) => {
  const newCorporateCustomer = {
    potentialCustomerId: randomInteger(1000000, 9999999),
    potentialCustomerAttribute: params.potentialCustomerAttribute,
    potentialCustomerType: params.potentialCustomerType,
    potentialCustomerFirstVisitOn: params.potentialCustomerFirstVisitOn,
    market: params.market,
    corporatePotentialCustomer: params.corporatePotentialCustomer,
    individualPotentialCustomer: params.individualPotentialCustomer,
    memo: params.memo,
    staffId: params.staffId,
    authorizedStaff: params.authorizedStaff,
  };

  const marketName = await getMarketName(
    String(newCorporateCustomer.market?.marketId)
  );
  const staffData = await getStaffData(newCorporateCustomer.staffId);
  //TODO: this does now allow for date, ie. is not checking against current date.
  const departmentId = staffData?.departmentAffiliation[0].departmentId;
  const departmentName = await getDepartmentName(Number(departmentId));

  const newInsureds = [
    // Corporation
    {
      potentialCustomerType: params.potentialCustomerType,
      market: {
        marketId: newCorporateCustomer.market?.marketId,
        marketName: marketName,
      },
      authorizedFlag: true,
      potentialCustomerId: newCorporateCustomer.potentialCustomerId,
      staff: {
        firstName: staffData.personal.firstName,
        lastName: staffData.personal.lastName,
        staffId: params.staffId,
        departmentName: departmentName,
        departmentId: departmentId,
      },
      corporation: {
        corporationName:
          newCorporateCustomer.corporatePotentialCustomer?.corporationName,
        address1: newCorporateCustomer.corporatePotentialCustomer?.address1,
        address2: newCorporateCustomer.corporatePotentialCustomer?.address2,
        address3: newCorporateCustomer.corporatePotentialCustomer?.address3,
        telLast: newCorporateCustomer.corporatePotentialCustomer?.tel1
          ? newCorporateCustomer.corporatePotentialCustomer?.tel1.slice(-4)
          : null,
      },
    },
  ];

  return [newCorporateCustomer, newInsureds] as [
    GetPotentialCustomer200Response,
    GetPotentialCustomerCorporations200Response["results"]
  ];
};

// Immemory database for individual
let customers: GetPotentialCustomer200Response[] = [
  {
    potentialCustomerAttribute: "individual",
    potentialCustomerFirstVisitOn: "2022-07-21",
    potentialCustomerId: 4162085,
    createdAt: "2024-12-05 14:42:26",
    potentialCustomerType: "market",
    authorizedStaff: [7777],
    individualPotentialCustomer: {
      family: [
        {
          address1: "東京都",
          address2: "新宿区",
          address3: "四谷",
          address4: "4-16-3",
          address5: "川辺新宿御苑前ビル8階",
          dateOfBirth: "2023-03-22",
          firstName: "イッセイ",
          firstNameKana: "イッセイ",
          lastName: "大澤",
          lastNameKana: "オオサワ",
          postCode: "1600004",
          potentialCustomerPersonAttribute: "partner",
          potentialCustomerPersonId: 6284719,
          potentialCustomerPersonSortNumber: 1,
          tel1: "0362748622",
          tel2: "0362748622",
          mailAddress: "system@doitplanning.com",
        },
        {
          address1: "東京都",
          address2: "新宿区",
          address3: "四谷",
          address4: "4-16-3",
          address5: "川辺新宿御苑前ビル8階",
          dateOfBirth: "2023-03-22",
          firstName: "ニセイ",
          firstNameKana: "ニセイ",
          lastName: "大澤",
          lastNameKana: "オオサワ",
          postCode: "1600004",
          potentialCustomerPersonAttribute: "child",
          potentialCustomerPersonId: 6284720,
          potentialCustomerPersonSortNumber: 2,
          tel1: "0362748622",
          tel2: "0362748622",
          mailAddress: "system@doitplanning.com",
        },
      ],
      person: {
        address1: "東京都",
        address2: "新宿区",
        address3: "四谷",
        address4: "4-16-3",
        address5: "川辺新宿御苑前ビル8階",
        dateOfBirth: "2022-07-21",
        firstName: "麻由美",
        firstNameKana: "アユミ",
        lastName: "大澤",
        lastNameKana: "オオサワ",
        postCode: "1600004",
        potentialCustomerPersonId: 9185535,
        tel1: "0362748622",
        tel2: "0362748622",
        mailAddress: "system@doitplanning.com",
      },
    },
    market: {
      marketCustomerId: "0000000",
      marketId: 9999999,
      supportUse: true,
    },
    memo: "メモ１行目\nメモ２行目",

    staffId: 1234567,
  },
  {
    potentialCustomerId: 7777333,
    createdAt: "2024-12-05 14:42:26",
    potentialCustomerAttribute: "corporation",
    potentialCustomerType: "market",
    potentialCustomerFirstVisitOn: "2022-07-21",
    market: {
      marketId: 1234567,
      marketCustomerId: "1234567",
      supportUse: true,
    },
    individualPotentialCustomer: {
      person: {
        potentialCustomerPersonId: 1234567,
        lastName: "大澤",
        firstName: "麻由美",
        lastNameKana: "オオサワ",
        firstNameKana: "アユミ",
        dateOfBirth: "2022-07-21",
        postCode: "1600004",
        address1: "東京都",
        address2: "新宿区",
        address3: "四谷",
        address4: "4-16-3",
        address5: "川辺新宿御苑前ビル8階",
        tel1: "0362748622",
        tel2: "0362748622",
        mailAddress: "system@doitplanning.com",
      },
      family: [
        {
          potentialCustomerPersonId: 1234567,
          potentialCustomerPersonAttribute: "partner",
          potentialCustomerPersonSortNumber: 1,
          lastName: "大澤",
          firstName: "麻由美",
          lastNameKana: "オオサワ",
          firstNameKana: "アユミ",
          dateOfBirth: "2023-03-22",
          postCode: "1600004",
          address1: "東京都",
          address2: "新宿区",
          address3: "四谷",
          address4: "4-16-3",
          address5: "川辺新宿御苑前ビル8階",
          tel1: "0362748622",
          tel2: "0362748622",
          mailAddress: "system@doitplanning.com",
        },
      ],
    },
    corporatePotentialCustomer: {
      corporationName: "アイティーソリューションズ株式会社",
      corporationClosingMonth: 12,
      corporationRepresentativeFirstName: "成規",
      corporationRepresentativeLastName: "岡田",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    memo: "メモ１行目\r\nメモ２行目",
    staffId: 1234567,
    authorizedStaff: [7777],
  },
  {
    potentialCustomerId: 3837462,
    createdAt: "2024-12-05 14:42:26",
    potentialCustomerAttribute: "individual",
    potentialCustomerType: "myself",
    potentialCustomerFirstVisitOn: "2022-07-21",
    market: {
      marketId: 9999999,
      marketCustomerId: "1234567",
      supportUse: true,
    },
    individualPotentialCustomer: {
      person: {
        potentialCustomerPersonId: 3837462,
        lastName: "田中",
        firstName: "惠美子",
        lastNameKana: "たなか",
        firstNameKana: "えみこ",
        dateOfBirth: "2022-07-21",
        postCode: "1600004",
        address1: "東京都",
        address2: "新宿区",
        address3: "四谷",
        address4: "4-16-3",
        address5: "川辺新宿御苑前ビル8階",
        tel1: "0362748622",
        tel2: "0362748622",
        mailAddress: "system@doitplanning.com",
      },
      family: [],
    },

    memo: "メモ１行目\r\nメモ２行目",
    staffId: 90909,
    authorizedStaff: [7777],
  },
];

// Type: GetPotentialCustomerIndividualPersons200ResponseResultsInner
let insureds = [
  {
    authorizedFlag: true,
    potentialCustomerId: 4162085,
    createdAt: "2024-12-05 14:42:26",
    staff: {
      staffId: 1234567,
      staffNumber: "1234567",
      firstName: "ルカ",
      lastName: "山中",
      departmentName: "本店",
      departmentId: 1,
    },
    searchHitPerson: {
      firstName: "麻由美",
      lastName: "大澤",
      potentialCustomerPersonAttribute: "themself",
      dateOfBirth: "2022-07-21",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      telLast: "8622",
    },
    potentialCustomerPerson: {
      firstName: "麻由美",
      lastName: "大澤",
    },
    market: {
      marketId: 9999999,
      marketName: "大澤マーケット",
    },
    potentialCustomerType: "market",
  },
  {
    authorizedFlag: true,
    potentialCustomerId: 4162085,
    createdAt: "2024-12-05 14:42:26",
    staff: {
      staffId: 1234567,
      staffNumber: "1234567",
      firstName: "ルカ",
      lastName: "山中",
      departmentName: "本店",
      departmentId: 1,
    },
    searchHitPerson: {
      firstName: "イッセイ",
      lastName: "大澤",
      potentialCustomerPersonAttribute: "partner",
      dateOfBirth: "2023-03-22",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      telLast: "8622",
    },
    potentialCustomerPerson: {
      firstName: "麻由美",
      lastName: "大澤",
    },
    market: {
      marketId: 9999999,
      marketName: "大澤マーケット",
    },
    potentialCustomerType: "market",
  },
  // The object for 大澤ニセイ
  {
    authorizedFlag: true,
    potentialCustomerId: 4162085,
    createdAt: "2024-12-05 14:42:26",
    staff: {
      staffId: 1234567,
      staffNumber: "1234567",
      firstName: "ルカ",
      lastName: "山中",
      departmentName: "本店",
      departmentId: 1,
    },
    searchHitPerson: {
      firstName: "ニセイ",
      lastName: "大澤",
      potentialCustomerPersonAttribute: "child",
      dateOfBirth: "2023-03-22",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      telLast: "8622",
    },
    potentialCustomerPerson: {
      firstName: "麻由美",
      lastName: "大澤",
    },
    market: {
      marketId: 9999999,
      marketName: "大澤マーケット",
    },
    potentialCustomerType: "market",
  },
  {
    authorizedFlag: true,
    potentialCustomerId: 3837462,
    createdAt: "2024-12-05 14:42:26",
    staff: {
      staffId: 90909,
      staffNumber: "00123",
      firstName: "Jane",
      lastName: "Smith",
      departmentName: "本店",
      departmentId: 1,
    },
    searchHitPerson: {
      firstName: "惠美子",
      lastName: "田中",
      potentialCustomerPersonAttribute: "themself",
      dateOfBirth: "2023-03-22",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      telLast: "8622",
    },
    potentialCustomerPerson: {
      firstName: "惠美子",
      lastName: "田中",
    },
    market: {
      marketId: 9999999,
      marketName: "大澤マーケット",
    },
    potentialCustomerType: "market",
  },
] as GetPotentialCustomerIndividualPersons200ResponseResultsInner[];

// Type: GetPotentialCustomerCorporations200ResponseResultsInner
let corporations = [
  {
    authorizedFlag: true,
    potentialCustomerId: 7777333,
    createdAt: "2024-12-05 14:42:26",
    potentialCustomerType: "myself",

    staff: {
      firstName: "ルカ",
      lastName: "山中",
      staffId: 1234567,
      staffNumber: "1234567",
      departmentName: "本店",
      departmentId: 1,
    },
    corporation: {
      corporationName: "アイティーソリューションズ株式会社",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      telLast: "8622",
    },
  },
] as (GetPotentialCustomerCorporations200ResponseResultsInner | undefined)[];

export const handlers = [
  /**
   * PotentialCustomerQuery
   * GetPotentialCustomerIndividualPersons
   */
  rest.get(
    `${BASE_PATH}/potentialCustomer/individual/person`,
    (req, res, ctx) => {
      // Desctructure params for findBy
      // const {
      //   page,
      //   numberPerPage,
      //   keyword,
      //   potentialCustomerId,
      //   staffId,
      //   marketId,
      //   potentialCustomerType,
      //   createdOnFrom,
      //   createdOnTo,
      // } = req.params;

      // define search params
      const keyword = req.url.searchParams.get("keyword");
      const potentialCustomerType = req.url.searchParams.get(
        "potentialCustomerType"
      );
      const marketId = req.url.searchParams.get("marketId");
      const staffId = req.url.searchParams.get("staffId");
      const potentialCustomerId = req.url.searchParams.get(
        "potentialCustomerId"
      );

      // if no parameters, return all results.
      let filteredCustomers = insureds;

      if (keyword) {
        filteredCustomers = filteredCustomers.filter((customer) =>
          `${customer.searchHitPerson?.firstName.toLowerCase()}
        ${customer.searchHitPerson?.lastName.toLowerCase()}
        }`.includes(keyword as string)
        );
      }
      if (potentialCustomerType) {
        filteredCustomers = filteredCustomers.filter((customer) => {
          return customer.potentialCustomerType == potentialCustomerType;
        });
      }
      if (marketId) {
        filteredCustomers = filteredCustomers.filter((customer) => {
          return customer.market?.marketId == Number(marketId);
        });
      }
      if (staffId) {
        filteredCustomers = filteredCustomers.filter((customer) => {
          return customer.staff?.staffId == Number(staffId);
        });
      }
      if (potentialCustomerId) {
        filteredCustomers = filteredCustomers.filter((customer) => {
          return customer.potentialCustomerId == Number(potentialCustomerId);
        });
      }

      return res(
        ctx.status(200),
        ctx.json({
          results: filteredCustomers,
        })
      );
    }
  ),

  /**
   * PotentialCustomerQuery
   * GetPotentialCustomerCorporations
   */
  rest.get(`${BASE_PATH}/potentialCustomer/corporation`, (req, res, ctx) => {
    // Desctructure params for findBy
    // const {
    //   page,
    //   numberPerPage,
    //   keyword,
    //   potentialCustomerId,
    //   staffId,
    //   marketId,
    //   potentialCustomerType,
    //   createdOnFrom,
    //   createdOnTo,
    // } = req.params;

    // define search params
    const keyword = req.url.searchParams.get("keyword");
    const potentialCustomerType = req.url.searchParams.get(
      "potentialCustomerType"
    );
    const marketId = req.url.searchParams.get("marketId");
    const staffId = req.url.searchParams.get("staffId");
    const potentialCustomerId = req.url.searchParams.get("potentialCustomerId");

    // if no parameters, return all results.
    let filteredCorporations = corporations;

    if (keyword) {
      filteredCorporations = filteredCorporations.filter((corporation) =>
        corporation?.corporation.corporationName.includes(keyword as string)
      );
    }
    if (potentialCustomerType) {
      filteredCorporations = filteredCorporations.filter((corporation) => {
        return corporation?.potentialCustomerType == potentialCustomerType;
      });
    }
    if (marketId) {
      filteredCorporations = filteredCorporations.filter((corporation) => {
        return corporation?.market?.marketId == Number(marketId);
      });
    }
    if (staffId) {
      filteredCorporations = filteredCorporations.filter((corporation) => {
        return corporation?.staff.staffId == Number(staffId);
      });
    }
    if (potentialCustomerId) {
      filteredCorporations = filteredCorporations.filter((corporation) => {
        return corporation?.potentialCustomerId == Number(potentialCustomerId);
      });
    }
    if (filteredCorporations.length === 0) {
      return res(
        ctx.status(404),
        ctx.json({
          message: "No results found",
        })
      );
    }
    return res(
      ctx.status(200),
      ctx.json({
        results: filteredCorporations,
      })
    );
  }),

  /**
   * PotentialCustomer
   * GetPotentialCustomer
   */
  rest.get(
    `${BASE_PATH}/potentialCustomer/:potentialCustomerId`,
    (req, res, ctx) => {
      const id = req.params.potentialCustomerId;
      const customer = customers.find(
        (customer) => customer.potentialCustomerId === Number(id)
      );
      if (customer == undefined) {
        return res(
          ctx.status(404),
          ctx.json({
            message: "No results found",
          })
        );
      }

      console.log("customer", customer);

      return res(ctx.status(200), ctx.json(customer));
    }
  ),

  /**
   * PotentialCustomer
   * PostPotentialCustomer
   */
  rest.post(`${BASE_PATH}/potentialCustomer`, async (req, res, ctx) => {
    const newCustomer = await req.json();
    if (newCustomer.potentialCustomerAttribute === "individual") {
      // 1. ensure params is valid
      const [customer, insured] = await convert(newCustomer);
      // 2. add it to customers in the store
      customers.push(customer);
      if (insured) insureds = _.concat(insureds, insured);
      return res(
        ctx.status(201),
        ctx.text(String(customer.potentialCustomerId))
      );
    } else {
      // 1. ensure params is valid
      const [customer, insured] = await convertCorporation(newCustomer);
      // 2. add it to customers in the store
      customers.push(customer);
      if (insured) corporations = _.concat(corporations, insured);
      return res(
        ctx.status(201),
        ctx.text(String(customer.potentialCustomerId))
      );
    }
  }),

  /**
   * PotentialCustomer
   * PutPotentialCustomer
   */

  rest.put(
    `${BASE_PATH}/potentialCustomer/:potentialCustomerId`,
    async (req, res, ctx) => {
      const id = req.params.potentialCustomerId;
      const customer = await req.json();
      if (customer.individualPotentialCustomer) {
        const [updatedCustomer, updatedInsured] = await convert(customer);
        const oldCustomer = customers.find(
          (customer) => customer.potentialCustomerId === Number(id)
        );
        const oldInsureds = insureds.filter(
          (insured) => insured?.potentialCustomerId === Number(id)
        );
        // Replace old customer with new customer
        customers = _.concat(
          _.filter(customers, (customer) => customer !== oldCustomer),
          updatedCustomer
        );

        // Replace old insureds with new insureds
        insureds = _.concat(
          _.filter(insureds, (insured) => !oldInsureds.includes(insured)),
          updatedInsured
        );
      } else {
        const [updatedCustomer, updatedCorporation] = await convertCorporation(
          customer
        );
        const oldCustomer = customers.find(
          (customer) => customer.potentialCustomerId === Number(id)
        );
        const oldCorporations = corporations.filter(
          (corporation) => corporation?.potentialCustomerId === Number(id)
        );
        // Replace old customer with new customer
        customers = _.concat(
          _.filter(customers, (customer) => customer !== oldCustomer),
          updatedCustomer
        );
        // Replace old insureds with new insureds
        corporations = _.concat(
          _.filter(
            corporations,
            (corporation) => !oldCorporations.includes(corporation)
          ),
          updatedCorporation
        );

        console.log("updatededCustomers", customers);
      }

      return res(ctx.status(204));
    }
  ),

  rest.delete(
    `${BASE_PATH}/potentialCustomer/:potentialCustomerId`,
    async (req, res, ctx) => {
      const id = req.params.potentialCustomerId;
      const index = customers.findIndex(
        (customer) => customer.potentialCustomerId === Number(id)
      );
      if (index === -1) {
        return res(ctx.status(404));
      }

      const target = customers.splice(index, 1)[0];
      console.log("target has been deleted: ", target);
      return res(ctx.status(204));
    }
  ),
];
