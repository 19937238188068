import { SecuritiesQueryApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getSecuritiesList,
  postSecuritiesCommissionMatching,
  putSecuritiesCommissionMatching,
  postSecuritiesCsv,
  getSecuritiesCsv,
  postSecuritiesShareCsv,
} = SecuritiesQueryApiFactory(...args, undefined, axiosInstance);

export default function useSecuritiesQueryService() {
  return {
    /**
     * Get security by the given parameters.
     * @param args
     */
    findBy: safeAsyncCall(
      async (...args: Parameters<typeof getSecuritiesList>) => {
        const res = await getSecuritiesList(...args);
        return res; // { data, status }
      }
    ),

    /**
     * Post match of security and commission
     * @param args
     */
    postSecuritiesCommissionMatching: safeAsyncCall(
      async (...args: Parameters<typeof postSecuritiesCommissionMatching>) => {
        const res = await postSecuritiesCommissionMatching(...args);
        return res; // { data, status }
      }
    ),

    /**
     * This function calls PutSecuritiesCommissionMatchingAPI.
     * If a status code other than 2xx is returned, an error is thrown.
     * @param args
     */
    putSecuritiesCommissionMatching: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof putSecuritiesCommissionMatching>) => {
        return await putSecuritiesCommissionMatching(...args);
      }
    ),

    /**
     * This function calls postSecuritiesCsvAPI with the csv file a user attaches.
     */
    importFile: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postSecuritiesCsv>) => {
        const res = await postSecuritiesCsv(...args);
        return res;
      }
    ),

    /**
     * This function calls GetSecuritiesCsv
     */
    exportBy: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof getSecuritiesCsv>) => {
        const res = await getSecuritiesCsv(...args);
        return res;
      }
    ),

    /**
     * This function calls PostSecuritiesShareCsv with the csv file a user attaches.
     */
    importShareFile: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postSecuritiesShareCsv>) => {
        const res = await postSecuritiesShareCsv(...args);
        return res;
      }
    ),
  };
}
